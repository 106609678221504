/**
 * Reusable stateless form component for Staff
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'

// import form components
import SelectFromArray from '../../../../global/components/forms/SelectFromArray.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import ToggleSwitchInput from '../../../../global/components/forms/ToggleSwitchInput.js.jsx'
import ReactTooltip from 'react-tooltip'

const PracticeStaffForm = ({
  cancelLink,
  firm,
  handleFormChange,
  handleFormSubmit,
  staff,
  submitting,
  toggleESigAccess,
  user,
  handleCreateApiUser,
  esignSubmitting,
}) => {
  const staffPermissionSet = ['owner', 'advanced', 'standard', 'basic']

  let permissionVal = ''
  if (staff.legacyPermission) {
    permissionVal = staff.owner ? 'owner' : 'standard'
  } else {
    permissionVal = staff.owner ? 'advanced' : 'basic'
  }

  return (
    <div className="yt-row">
      <div className="yt-col m_60 l_50">
        <div className="-practice-content">
          <p>
            <strong>Staff Member: </strong>
          </p>
          <p>
            {user.firstname} {user.lastname}
          </p>
          <p>{user.username}</p>
          <hr />
          <br />
          <SelectFromArray
            change={handleFormChange}
            label="Status"
            name="staff.status"
            items={['active', 'inactive']}
            placeholder={'-- Set status --'}
            required={true}
            value={staff.status}
          />
          {/* <CheckboxInput
            name='staff.owner'
            label='This staff member has owner privileges'
            value={staff.owner}
            change={handleFormChange}
            checked={staff.owner}
          /> */}
          <div id="newPermissionSetGroup">
            {/* <CheckboxInput
              name='staff.legacyPermission'
              label={`Use new permission set for ${staff.owner ? 'owner' : 'standard'}`}
              value={!staff.legacyPermission}
              change={handleFormChange}
              checked={!staff.legacyPermission}
            /> */}
            <SelectFromArray
              change={handleFormChange}
              label="Permission Level"
              name="staff.permissionlevel"
              items={[...staffPermissionSet]}
              placeholder={'-- Set permission --'}
              required={true}
              value={permissionVal}
            />
            <div
              className="tooltip-container"
              data-tip
              data-for="TL_NewPermission"
            >
              <div className="tool-tip-label">View new permission set</div>
              <ReactTooltip
                id="TL_NewPermission"
                place="right"
                type="info"
                effect="solid"
              >
                {/* <span className="tooltipMessage">You don't have permission to <br/> export data</span> */}
                <div className="tooltip-message-container">
                  <div className="tl-advanced-container">
                    <span className="inner-label">Advanced</span>
                    <ul>
                      <li>
                        Access
                        <ul className="inner-ul">
                          <li>Only assigned workspace access</li>
                          <li>Ability to grant member access</li>
                          <li>Ability to remove member access</li>
                          <li>Add and remove client's contact</li>
                          <li>Ability to resend invite to contacts</li>
                          <li>Ability to invite contacts</li>
                          <li>Ability to resend contacts password</li>
                        </ul>
                      </li>
                      <li>
                        No Access
                        <ul className="inner-ul">
                          <li>Firm Settings</li>
                          <li>All workspaces not assigned to</li>
                          <li>
                            Client contact info available only within assigned
                            workspace
                          </li>
                          <li>No access to General Files or Staff Files</li>
                          <li>No abiliy to user folder templates</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tl-basic-container">
                    <span className="inner-label">Basic</span>
                    <ul>
                      <li>
                        Access
                        <ul className="inner-ul">
                          <li>
                            View workspaces assigned to including files in it
                          </li>
                          <li>Notifications - Receive</li>
                        </ul>
                      </li>
                      <li>
                        No Access
                        <ul className="inner-ul">
                          <li>
                            Files Section General File, Staff Files, and
                            Contacts
                          </li>
                          <li>E-Signature</li>
                          <li>File Sharing</li>
                          <li>File Request</li>
                          <li>Notification Editing</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </ReactTooltip>
            </div>
          </div>
          <ToggleSwitchInput
            change={handleFormChange}
            disabled={submitting}
            label="Show General Files"
            helpText={submitting ? 'Submitting...' : ''}
            name="staff.showGeneralFiles"
            rounded={true}
            value={staff.showGeneralFiles}
          />
          <ToggleSwitchInput
            change={handleFormChange}
            disabled={submitting}
            label="Show Staff Folders"
            helpText={submitting ? 'Submitting...' : ''}
            name="staff.showStaffFolder"
            rounded={true}
            value={staff.showStaffFolder}
          />
          <ToggleSwitchInput
            change={handleFormChange}
            disabled={submitting}
            label={'Allow export of data'}
            helpText={submitting ? 'Submitting...' : ''}
            name="staff.allowExport"
            rounded={true}
            value={staff.allowExport}
          />
          <ToggleSwitchInput
            change={handleFormChange}
            disabled={submitting}
            label={'Allow direct link'}
            helpText={submitting ? 'Submitting...' : ''}
            name="staff.allowDirectLink"
            rounded={true}
            value={staff.allowDirectLink}
          />
          <div>
            <ToggleSwitchInput
              change={handleFormChange}
              disabled={submitting}
              label={'Login using Microsoft Account'}
              helpText={submitting ? 'Submitting...' : ''}
              name="staff.enableMSLogin"
              rounded={true}
              value={staff.enableMSLogin}
            />
            <TextInput
              required={false}
              value={staff.msUsername}
              placeholder="Microsoft Account Email"
              change={handleFormChange}
              name="staff.msUsername"
              helpText={
                'Note: When opting for your Microsoft account, the username and password login will be disabled.'
              }
              disabled={!staff.enableMSLogin}
            ></TextInput>
          </div>
          {firm && firm.eSigAccess ? (
            <>
              <hr />
              <br />
              <div>
                <ToggleSwitchInput
                  change={toggleESigAccess}
                  disabled={esignSubmitting}
                  label={'E-Signature Access'}
                  helpText={
                    esignSubmitting
                      ? 'Submitting...'
                      : staff.apiKey && staff.apiUsername
                      ? ''
                      : 'Note: This may take a minute.'
                  }
                  name={'eSigAccess'}
                  rounded={true}
                  value={staff.eSigAccess}
                />
                <TextInput
                  required={false}
                  value={staff.eSigEmail}
                  placeholder="E-Signature Custom Email"
                  change={handleFormChange}
                  name="staff.eSigEmail"
                  helpText={
                    'Note: Use this if your AssureSign email is different from your MangoShare email.'
                  }
                  classes="input-add-on-mb-0"
                ></TextInput>
                <div className="input-group">
                  <button
                    className="yt-btn"
                    disabled={submitting || !staff.eSigAccess}
                    onClick={handleCreateApiUser}
                  >
                    Verify staff in Assuresign
                  </button>
                </div>
              </div>
            </>
          ) : null}
          <div className="input-group">
            <div className="yt-row space-between">
              <Link
                className="yt-btn link"
                to={cancelLink}
              >
                Cancel
              </Link>
              <button
                className="yt-btn "
                onClick={handleFormSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PracticeStaffForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
  firm: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  toggleESigAccess: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

PracticeStaffForm.defaultProps = {
  formHelpers: {},
}

export default PracticeStaffForm
