/**
 * View component for /firm/:firmId/clients/:clientId/contacts
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'
import { Switch, Route } from 'react-router-dom'

// import third-party libraries
import _slice from 'lodash/slice'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as clientUserActions from '../clientUserActions'
import * as clientActions from '../../client/clientActions'
import * as userActions from '../../user/userActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import PageTabber from '../../../global/components/pagination/PageTabber.js.jsx'
import CloseWrapper from '../../../global/components/helpers/CloseWrapper.js.jsx'
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import MobileActionsOption from '../../../global/components/helpers/MobileActionOptions.js.jsx'
import PracticeInviteClientUser from '../practice/views/PracticeInviteClientUser.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

// import firm components
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import displayUtils from '../../../global/utils/displayUtils'

// import resource components
import ClientUserTableListItem from './ClientUserTableListItem.js.jsx'
import SingleClientUserOptions from '../practice/components/SingleClientUserOptions.js.jsx'
import { TextInput } from '../../../global/components/forms'

class ClientUserList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
      selectedContact: '',
      selectedClientUserId: [],
      sendingInvite: false,
      sendingReset: false,
      successInvite: false,
      successReset: false,
      archiveProcess: false,
      deleteProcess: false,
      removeProcess: false,
      viewToggleDropDown: false,
      checked: false,
      showAlertModal: false,
      showMobileActionOption: false,
      bulkResendInvite: false,
      isAddContactsModalOpen: false,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        email: { disableSearch: true, searchText: '' },
        position: { disableSearch: true, searchText: '' },
        phoneNumber: { disableSearch: true, searchText: '' },
        address: { disableSearch: true, searchText: '' },
      },
    }
    // this._bind(
    //   '_handleSelectedTagsChange'
    // )

    this._bind(
      '_handleResendInvite',
      '_handleResetPassword',
      '_handleCloseViewArchive',
      '_handleSelectedAllClientUser',
      '_handleSelectedClientUser',
      '_handleBulkArchiveClientUser',
      '_toggleAlertModal',
      '_handleCloseMobileOption',
      '_handleBulkResendInvite',
      '_handleAddContactsModal',
      '_toggleHeaderSearch',
      '_changeHeaderSearch'
    )
  }

  componentDidCatch() {
    const { dispatch, match } = this.props
    dispatch(userActions.fetchListIfNeeded('_firmStaff', match.params.firmId))
    dispatch(userActions.fetchListIfNeeded('_client', match.params.clientId))
    dispatch(
      clientUserActions.fetchListIfNeeded('_client', match.params.clientId)
    )
  }

  _handleCloseViewArchive(e) {
    e.stopPropagation()
    this.setState({ viewToggleDropDown: false })
  }

  _toggleAlertModal() {
    this.setState({ showAlertModal: !this.state.showAlertModal })
  }

  _handleResendInvite(currentUser, clientUser) {
    const { dispatch, match } = this.props
    const user = _cloneDeep(currentUser)

    this.setState({ selectedContact: user._id, sendingInvite: true })

    const firmId = match.params.firmId
    const clientId = match.params.clientId
    user._clientUser = clientUser._id
    const sendData = {
      invitations: [user],
      clientId,
      firmId,
    }

    try {
      dispatch(clientUserActions.sendResendInviteClientUsers(sendData)).then(
        clientUserRes => {
          if (clientUserRes.success) {
            clientUser.accessType = 'invitesent'
            dispatch(
              clientUserActions.updateSingleClientToMap({
                clientUser: clientUser,
                success: true,
              })
            )
            this.setState({ sendingInvite: false, successInvite: true })
            setTimeout(() => {
              this.setState({ successInvite: false })
            }, 2000)
            console.log('Successfully resend the invite')
          } else {
            this.setState({ sendingInvite: false, selectedContact: '' })
            alert('ERROR - Check logs')
          }
        }
      )
    } catch (err) {
      console.log(err)
      this.setState({ sendingInvite: false, selectedContact: '' })
    }
  }

  _handleResetPassword(user) {
    const { dispatch, match } = this.props

    this.setState({ sendingReset: true, selectedContact: user._id })

    if (user) {
      const sendData = {
        clientId: match.params.clientId,
        user: user,
      }

      try {
        dispatch(clientUserActions.sendInviteWithResetUser(sendData)).then(
          json => {
            if (json.success) {
              this.setState({ sendingReset: false, successReset: true })
              setTimeout(() => {
                this.setState({ successReset: false })
              }, 2000)
            } else {
              this.setState({ sendingReset: false, selectedContact: '' })
            }
          }
        )
      } catch (err) {
        console.log('ERROR', err)
        this.setState({ sendingReset: false, selectedContact: '' })
      }
    }
  }

  _handleSelectedAllClientUser() {
    const { paginatedList } = this.props
    if (!this.state.checked) {
      const clientUserIds = paginatedList.map(clientUser => clientUser._id)
      this.setState({ selectedClientUserId: clientUserIds, checked: true })
    } else {
      this.setState({ selectedClientUserId: [], checked: false })
    }
  }

  _handleSelectedClientUser(clientUserId) {
    const { paginatedList } = this.props
    let newclientUserIds = _cloneDeep(this.state.selectedClientUserId)
    let checked = false
    if (newclientUserIds.indexOf(clientUserId) === -1) {
      newclientUserIds.push(clientUserId)
      checked = paginatedList.length === newclientUserIds.length
    } else {
      newclientUserIds.splice(newclientUserIds.indexOf(clientUserId), 1)
    }
    this.setState({
      selectedClientUserId: newclientUserIds,
      checked,
    })
  }

  _setSelectedClientUser() {
    // console.log("selectedClientUserId", this.state);
    // this.setState({ selectedClientUserId: [] })
  }

  _handleBulkArchiveClientUser(status) {
    const { dispatch } = this.props
    const { selectedClientUserId } = this.state

    const data = { status: status, clientUserIds: selectedClientUserId }

    if (status == 'deleted') {
      this.setState({ deleteProcess: true })
    } else {
      this.setState({ archiveProcess: true })
    }

    dispatch(clientUserActions.sendBulkUpdateClientUser(data)).then(json => {
      if (status === 'deleted') {
        this._toggleAlertModal()
      }
      if (json.success && json.client) {
        dispatch(
          clientActions.updateSingleClientToMap({
            client: json.client,
            success: true,
          })
        )
      }
      this.setState({
        deleteProcess: false,
        archiveProcess: false,
        selectedClientUserId: [],
        checked: false,
      })
    })
  }

  _handleCloseMobileOption(e) {
    e.stopPropagation()
    this.setState({ showMobileActionOption: false })
  }

  _handleBulkResendInvite() {
    const { dispatch, match, userMap, clientUserMap } = this.props
    const { selectedClientUserId } = this.state

    this.setState({ bulkResendInvite: true })

    const firmId = match.params.firmId
    const clientId = match.params.clientId

    const getInvitation = cb => {
      if (selectedClientUserId && selectedClientUserId.length) {
        const result = selectedClientUserId.map(val => {
          if (val && clientUserMap[val]) {
            const user = _cloneDeep(userMap[clientUserMap[val]._user])
            if (user) {
              user._clientUser = val
            }
            return user
          } else {
            return null
          }
        })
        cb(result)
      } else {
        cb(false)
      }
    }

    getInvitation(invitations => {
      if (invitations) {
        const sendData = {
          invitations: invitations,
          firmId,
          clientId,
        }
        dispatch(clientUserActions.sendResendInviteClientUsers(sendData)).then(
          clientUserRes => {
            if (clientUserRes.success) {
              this.setState({
                bulkResendInvite: false,
                selectedClientUserId: [],
                checked: false,
              })
            } else {
              this.setState({ bulkResendInvite: false })
              alert('ERROR - Check logs')
            }
          }
        )
      } else {
        this.setState({ bulkResendInvite: false })
        alert('ERROR - Check logs')
      }
    })
  }

  _handleAddContactsModal(status) {
    this.setState({ isAddContactsModalOpen: status })
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { clientStore, dispatch } = this.props
    const { filterHeaders } = clientStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(clientActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { clientStore, dispatch } = this.props
    const { filterHeaders } = clientStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(clientActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  render() {
    const {
      allTags,
      clientUserList,
      handleSetPagination,
      match,
      paginatedList,
      selectedTagIds,
      sortedAndFilteredList,
      archived,
      listItems,
    } = this.props

    const {
      selectedClientUserId,
      archiveProcess,
      removeProcess,
      deleteProcess,
      viewToggleDropDown,
      checked,
      showAlertModal,
      showMobileActionOption,
      bulkResendInvite,
      isAddContactsModalOpen,
      searchHeaderColumns,
    } = this.state

    const disableButton =
      !selectedClientUserId.length ||
      deleteProcess ||
      archiveProcess ||
      bulkResendInvite

    return (
      <div className="-client-user-list-wrapper">
        {match.params.firmId ? (
          <div className="yt-toolbar">
            <div className="yt-tools space-between">
              <div className="-filters -left">
                {/* TODO: Set up filters. */}
                {/* <strong>Filter by: </strong>
                <FilterBy
                  applyFilter={this._handleSelectedTagsChange}
                  displayKey="name"
                  items={allTags || []}
                  label="Tags"
                  name="_tags"
                  selected={selectedTagIds}
                  valueKey="_id"
                /> */}
              </div>
              <div className="-options -right">
                {/* <DisplayAsButtons
                  displayAs={this.state.viewingAs}
                  displayGrid={() => this.setState({viewingAs: 'grid'})}
                  displayTable={() => this.setState({viewingAs: 'table'})}
                /> */}
                {
                  //&& paginatedList.length > 0
                  !archived && (
                    <div style={{ marginLeft: '0' }}>
                      <button
                        className="yt-btn x-small link info"
                        disabled={disableButton}
                        style={{ display: 'inline-flex' }}
                        onClick={e =>
                          this._handleBulkArchiveClientUser('archived')
                        }
                      >
                        {archiveProcess ? (
                          <p className="-archive-saving">
                            Archiving<span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </p>
                        ) : (
                          'Archive Contacts'
                        )}
                        {archiveProcess ? null : selectedClientUserId.length ? (
                          <span> — {selectedClientUserId.length}</span>
                        ) : null}
                      </button>
                      <button
                        className="yt-btn x-small link info"
                        disabled={disableButton}
                        style={{ display: 'inline-flex' }}
                        onClick={this._handleBulkResendInvite}
                      >
                        {bulkResendInvite ? (
                          <p className="-archive-saving">
                            Sending<span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </p>
                        ) : (
                          'Resend Invite'
                        )}
                        {bulkResendInvite ? null : selectedClientUserId.length ? (
                          <span> — {selectedClientUserId.length}</span>
                        ) : null}
                      </button>
                      <button
                        className="yt-btn x-small info"
                        style={{ display: 'inline-flex' }}
                        onClick={() => this._handleAddContactsModal(true)}
                      >
                        Add Contacts
                      </button>
                    </div>
                  )
                }
                {archived && (
                  <div style={{ marginLeft: '0' }}>
                    <button
                      className="yt-btn x-small link info"
                      disabled={disableButton}
                      style={{ display: 'inline-flex' }}
                      onClick={e => {
                        this._handleBulkArchiveClientUser('active')
                      }}
                    >
                      {archiveProcess ? (
                        <p className="-archive-saving">
                          Reinstating<span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </p>
                      ) : (
                        'Reinstate Contacts '
                      )}
                      {archiveProcess ? null : selectedClientUserId.length ? (
                        <span> — {selectedClientUserId.length}</span>
                      ) : null}
                    </button>
                    <button
                      className="yt-btn x-small link info"
                      disabled={disableButton}
                      style={{ display: 'inline-flex' }}
                      onClick={this._toggleAlertModal}
                    >
                      {deleteProcess ? (
                        <p className="-archive-saving">
                          Removing<span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </p>
                      ) : (
                        'Remove from Client '
                      )}
                      {deleteProcess ? null : selectedClientUserId.length ? (
                        <span> — {selectedClientUserId.length}</span>
                      ) : null}
                    </button>
                  </div>
                )}

                <div
                  className="-options -yt-edit-option"
                  onClick={() => this.setState({ viewToggleDropDown: true })}
                >
                  <div
                    style={{
                      position: 'relative',
                      height: '100%',
                      width: '100%'
                    }}
                  >
                    <CloseWrapper
                      isOpen={viewToggleDropDown}
                      closeAction={this._handleCloseViewArchive}
                    />
                    <i className="far fa-ellipsis-v"></i>
                    <SingleClientUserOptions
                      isOpen={viewToggleDropDown}
                      archived={archived}
                      singleClientUser={false}
                      closeAction={this._handleCloseViewArchive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <hr className="-mobile-yt-hide" />
        <table
          className="yt-table firm-table -workspace-table truncate-cells"
          style={{ marginTop: 0 }}
        >
          <caption
            className="-border-bottom"
            style={{ borderBottom: '1px solid #aaa' }}
          >
            <PageTabber
              totalItems={listItems && listItems.length}
              totalPages={Math.ceil(
                listItems && listItems.length / clientUserList.pagination.per
              )}
              pagination={clientUserList.pagination}
              setPagination={handleSetPagination}
              setPerPage={this.props.setPerPage}
              viewingAs="top"
              itemName="contacts"
              searchText="Search..."
            />
          </caption>
          <thead>
            <TableHeaderColumn
              trClasses=""
              trStyles={{}}
              checked={checked}
              handleSelectedAllClientUser={this._handleSelectedAllClientUser}
              toggleHeaderSearch={this._toggleHeaderSearch}
              changeHeaderSearch={this._changeHeaderSearch}
              searchHeaderColumns={searchHeaderColumns}
            />
          </thead>
          <tbody>
            <TableHeaderColumn
              trClasses="-table-header-mobile-layout"
              trStyles={{ display: 'none' }}
              checked={checked}
              handleSelectedAllClientUser={this._handleSelectedAllClientUser}
              toggleHeaderSearch={this._toggleHeaderSearch}
              changeHeaderSearch={this._changeHeaderSearch}
              searchHeaderColumns={searchHeaderColumns}
            />
            {paginatedList && paginatedList.length ? (
              paginatedList.map((clientUser, i) => (
                <ClientUserTableListItem
                  clientUser={clientUser}
                  key={clientUser._id + '_key ' + i}
                  handleResendInvite={this._handleResendInvite}
                  handleResetPassword={this._handleResetPassword}
                  selectedContact={this.state.selectedContact}
                  sendingInvite={this.state.sendingInvite}
                  successInvite={this.state.successInvite}
                  sendingReset={this.state.sendingReset}
                  successReset={this.state.successReset}
                  handleSelectedClientUser={this._handleSelectedClientUser}
                  checked={selectedClientUserId.includes(clientUser._id)}
                  archived={archived}
                  setSelectedClientUser={this._setSelectedClientUser}
                />
              ))
            ) : (
              <tr>
                <td colSpan="8">
                  <div className="hero -empty-hero">
                    <div className="u-centerText">
                      <h3>
                        <em>No client contacts</em>
                      </h3>
                      <br />
                      {!archived && (
                        //<Link className="yt-btn rounded info" to={`/firm/${match.params.firmId}/workspaces/${match.params.clientId}/contacts/invite`}>Add client contacts</Link>
                        <button
                          className="yt-btn rounded info"
                          style={{ display: 'inline-flex' }}
                          onClick={() => this._handleAddContactsModal(true)}
                        >
                          Add client Contacts
                        </button>
                      )}
                      <br />
                      <br />
                      <p>
                        Client contacts are individuals associated with this
                        client. They receive notifications, can ask & answer
                        questions, and can view all client files.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isAddContactsModalOpen && (
          <PracticeInviteClientUser
            handleAddContactsModal={this._handleAddContactsModal}
          />
        )}
        <AlertModal
          alertMessage={'Are you sure? This cannot be undone.'}
          alertTitle={'Remove these contacts from Client?'}
          closeAction={this._toggleAlertModal}
          confirmAction={() => {
            this._handleBulkArchiveClientUser('deleted')
          }}
          confirmText={'Delete'}
          declineAction={this._toggleAlertModal}
          declineText={'Cancel'}
          isOpen={showAlertModal}
          type={'danger'}
        ></AlertModal>
      </div>
    )
  }
}

const TableHeaderColumn = ({
  trClasses,
  trStyles,
  checked,
  handleSelectedAllClientUser,
  toggleHeaderSearch,
  changeHeaderSearch,
  searchHeaderColumns,
}) => {
  return (
    <tr
      className={trClasses}
      style={trStyles}
    >
      <th>
        <div style={{ width: '25px', display: 'inline-flex' }}>
          <CheckboxInput
            name="clients"
            value={checked}
            checked={checked}
            change={handleSelectedAllClientUser}
          />
        </div>
      </th>
      <th></th>
      <th className="sortable _40">
        <div className="-table-header-title">Name</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="name"
            value={!searchHeaderColumns.name.disableSearch}
            checked={!searchHeaderColumns.name.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="name"
            value={searchHeaderColumns.name.searchText}
            disabled={searchHeaderColumns.name.disableSearch}
            placeholder="Search name"
          />
        </div>
      </th>
      <th className="sortable _40">
        <div className="-table-header-title">Email</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="email"
            value={!searchHeaderColumns.email.disableSearch}
            checked={!searchHeaderColumns.email.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="email"
            value={searchHeaderColumns.email.searchText}
            disabled={searchHeaderColumns.email.disableSearch}
            placeholder="Search email"
          />
        </div>
      </th>
      <th className="sortable _40">
        <div className="-table-header-title">Position</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="position"
            value={!searchHeaderColumns.position.disableSearch}
            checked={!searchHeaderColumns.position.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="position"
            value={searchHeaderColumns.position.searchText}
            disabled={searchHeaderColumns.position.disableSearch}
            placeholder="Search position"
          />
        </div>
      </th>
      <th className="sortable _40">
        <div className="-table-header-title">Phone Number</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="phoneNumber"
            value={!searchHeaderColumns.phoneNumber.disableSearch}
            checked={!searchHeaderColumns.phoneNumber.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="phoneNumber"
            value={searchHeaderColumns.phoneNumber.searchText}
            disabled={searchHeaderColumns.phoneNumber.disableSearch}
            placeholder="Search phone number"
          />
        </div>
      </th>
      <th className="sortable _40">
        <div className="-table-header-title">Address</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="address"
            value={!searchHeaderColumns.address.disableSearch}
            checked={!searchHeaderColumns.address.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="address"
            value={searchHeaderColumns.address.searchText}
            disabled={searchHeaderColumns.address.disableSearch}
            placeholder="Search address"
          />
        </div>
      </th>
      <th></th>
    </tr>
  )
}

ClientUserList.propTypes = {
  clientUserList: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sortedAndFilteredList: PropTypes.array,
  viewingAs: PropTypes.string,
}

ClientUserList.defaultProps = {
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  const { clientUserList, clientUserListItems } = props
  const clientStore = store.clientUser
  const userMap = store.user.byId
  const listItems = []
  let paginatedList = []
  if (clientUserListItems) {
    const pagination = clientUserList.pagination || { page: 1, per: 50 }

    clientUserListItems.forEach(item => {
      // praparing data ---------------------------------------------------------------------------------------------------------
      item.userFullName = ''
      item.userEmail = ''
      item.phonenumber = null
      item.address = null

      if (userMap[item._user]) {
        const user = userMap[item._user]
        item.userFullName = user.firstname || ''
        item.userFullName += (user.firstname ? ' ' : '') + user.lastname || ''
        item.userEmail = user.username

        if (
          user._primaryPhone &&
          store.phoneNumber &&
          store.phoneNumber.byId &&
          store.phoneNumber.byId[user._primaryPhone]
        ) {
          item.phonenumber = store.phoneNumber.byId[user._primaryPhone]
        }

        if (
          user._primaryAddress &&
          store.address &&
          store.address.byId &&
          store.address.byId[user._primaryAddress]
        ) {
          item.address = store.address.byId[user._primaryAddress]
        }
      }

      item.phoneNumber =
        item.phonenumber &&
        item.phonenumber.number &&
        displayUtils.formatPhoneNumber(item.phonenumber.number, 'National')
          ? displayUtils.formatPhoneNumber(item.phonenumber.number, 'National')
          : null
      if (item.phonenumber && item.phonenumber.extNumber) {
        item.phoneNumber += ' ' + item.phonenumber.extNumber
      }
      if (item.address) {
        const address = item.address
        item.address = address.street1 || ''
        item.address += (item.address ? ' ' : '') + address.city || ''
        item.address +=
          (item.address ? ' ' : '') + ` ${address.state ? address.state : ''}`
        item.address += (item.address ? ' ' : '') + address.country || ''
      }

      // filtering data -------------------------------------------------------------------------------------------------------------------
      let showMe = true
      if (
        showMe &&
        !!clientStore.filterHeaders &&
        !!clientStore.filterHeaders.name &&
        clientStore.filterHeaders.name.trim()
      ) {
        const name = clientStore.filterHeaders.name.toLowerCase()
        showMe =
          item.userFullName &&
          item.userFullName.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!clientStore.filterHeaders &&
        !!clientStore.filterHeaders.email &&
        clientStore.filterHeaders.email.trim()
      ) {
        const email = clientStore.filterHeaders.email.toLowerCase()
        showMe =
          item.userEmail && item.userEmail.toLowerCase().indexOf(email) > -1
      }

      if (
        showMe &&
        !!clientStore.filterHeaders &&
        !!clientStore.filterHeaders.position &&
        clientStore.filterHeaders.position.trim()
      ) {
        const position = clientStore.filterHeaders.position.toLowerCase()
        showMe =
          item.position && item.position.toLowerCase().indexOf(position) > -1
      }

      if (
        showMe &&
        !!clientStore.filterHeaders &&
        !!clientStore.filterHeaders.address &&
        clientStore.filterHeaders.address.trim()
      ) {
        const address = clientStore.filterHeaders.address.toLowerCase()
        showMe =
          item.address && item.address.toLowerCase().indexOf(address) > -1
      }

      if (
        showMe &&
        !!clientStore.filterHeaders &&
        !!clientStore.filterHeaders.phoneNumber &&
        clientStore.filterHeaders.phoneNumber.trim()
      ) {
        const phoneNumber = clientStore.filterHeaders.phoneNumber
        showMe = item.phoneNumber && item.phoneNumber.indexOf(phoneNumber) > -1
      }

      if (showMe) listItems.push(item)
    })

    console.log('listItems', listItems)

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _slice(listItems, start, end)
  }

  return {
    paginatedList: paginatedList,
    userMap,
    clientUserMap: store.clientUser.byId,
    clientStore,
    listItems,
  }
}

export default withRouter(connect(mapStoreToProps)(ClientUserList))
