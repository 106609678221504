// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import FilterBy from '../../../../global/components/helpers/FilterBy.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'
import DisplayAsButtons from '../../../../global/components/helpers/DisplayAsButtons.js.jsx'
import MobileActionsOption from '../../../../global/components/helpers/MobileActionOptions.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import SearchInput from '../../../../global/components/forms/SearchInput.js.jsx'

import * as tagActions from '../../tagActions.js'

// import utilities
import filterUtils from '../../../../global/utils/filterUtils'

// import resource components
import PracticeTagTableListItem from './PracticeTagTableListItem.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import CreateTagModal from './CreateTagModal.js.jsx'

class PracticeTagList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
      showMobileActionOption: false,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        type: { disableSearch: true, searchText: '' },
        createdBy: { disableSearch: true, searchText: '' },
        color: { disableSearch: true, searchText: '' },
      },
      isCreateTagModalOpen: false,
    }
    this._bind(
      '_handleCloseMobileOption',
      '_toggleHeaderSearch',
      '_changeHeaderSearch',
      '_handleNewTag',
    )
  }

  _handleCloseMobileOption(e) {
    e.stopPropagation()
    this.setState({ showMobileActionOption: false })
  }

  componentWillUnmount() {
    this.setState({
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        type: { disableSearch: true, searchText: '' },
        createdBy: { disableSearch: true, searchText: '' },
        color: { disableSearch: true, searchText: '' },
      },
    })
    this.props.dispatch(tagActions.setHeaderFilter({}))
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { tagStore, dispatch } = this.props
    const { filterHeaders } = tagStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(tagActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { tagStore, dispatch } = this.props
    const { filterHeaders } = tagStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(tagActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _handleNewTag(tag) {
    const { dispatch, match } = this.props
    dispatch(tagActions.addTagToList(tag, '~firm', match.params.firmId))
    this.setState({ isCreateTagModalOpen: false })
  }

  render() {
    const {
      handleSetPagination,
      handleQuery,
      paginatedList,
      query,
      sortedAndFilteredList,
      tagList,
      userMap,
      handleShowNewTagModal,
      deleteModal,
    } = this.props

    const { showMobileActionOption, searchHeaderColumns } = this.state

    return (
      <div className="tag-list-wrapper -list-wrapper-80-yt-col">
        <div className="yt-toolbar">
          <div className="yt-tools space-between">
            <div>
              <SearchInput
                name="query"
                value={query}
                change={handleQuery}
                placeholder="Search..."
                required={false}
              />
            </div>
            <div>
              <button
                className="yt-btn block success x-small"
                onClick={() => this.setState({ isCreateTagModalOpen: true })}
              >
                New Tag
              </button>
            </div>
          </div>
          <div>
            <span>
              <i
                className="fas fa-lock"
                style={{ paddingRight: '.5em' }}
              />
              <small>This denotes a global tag that cannot be edited.</small>
            </span>
          </div>
        </div>
        {this.state.viewingAs === 'grid' ? (
          <div className="file-grid">
            {/*
             * TODO: Create a PracticeTagGridListItem
             */}
          </div>
        ) : (
          <table className="yt-table firm-table -workspace-table truncate-cells">
            <caption>
              <PageTabber
                totalItems={tagList.items.length}
                totalPages={Math.ceil(
                  tagList.items.length / tagList.pagination.per,
                )}
                pagination={tagList.pagination}
                setPagination={handleSetPagination}
                setPerPage={this.props.setPerPage}
                viewingAs="top"
                itemName="tags"
                searchText="Search..."
              />
            </caption>
            <thead>
              <TableHeaderColumn
                trClasses=""
                trStyles={{}}
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
                searchHeaderColumns={searchHeaderColumns}
              />
            </thead>
            <tbody>
              <TableHeaderColumn
                trClasses="-table-header-mobile-layout"
                trStyles={{ display: 'none' }}
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
                searchHeaderColumns={searchHeaderColumns}
              />
              {paginatedList.length > 0 ? (
                paginatedList.map((tag, i) => {
                  return (
                    <PracticeTagTableListItem
                      key={'tag_' + tag._id + '_' + i}
                      tag={tag}
                      deleteModal={deleteModal}
                    />
                  )
                })
              ) : (
                <tr className="empty-state">
                  <td colSpan="4">
                    <em>No tags</em>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <CreateTagModal
          close={() => this.setState({ isCreateTagModalOpen: false })}
          firmId={parseInt(this.props.match.params.firmId)}
          handleNewTag={this._handleNewTag}
          tag={null}
          isOpen={this.state.isCreateTagModalOpen}
        />
      </div>
    )
  }
}

const TableHeaderColumn = ({
  trClasses,
  trStyles,
  toggleHeaderSearch,
  changeHeaderSearch,
  searchHeaderColumns,
}) => {
  return (
    <tr
      className={trClasses}
      style={trStyles}
    >
      <th className="-title sortable _40">
        <div className="-table-header-title">Name</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="name"
            value={!searchHeaderColumns.name.disableSearch}
            checked={!searchHeaderColumns.name.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="name"
            value={searchHeaderColumns.name.searchText}
            disabled={searchHeaderColumns.name.disableSearch}
            placeholder="Search name"
          />
        </div>
      </th>
      <th className="-title sortable _20">
        <div className="-table-header-title">Type</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="type"
            value={!searchHeaderColumns.type.disableSearch}
            checked={!searchHeaderColumns.type.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="type"
            value={searchHeaderColumns.type.searchText}
            disabled={searchHeaderColumns.type.disableSearch}
            placeholder="Search type"
          />
        </div>
      </th>
      <th className="-title sortable _40">
        <div className="-table-header-title">Created By</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="createdBy"
            value={!searchHeaderColumns.createdBy.disableSearch}
            checked={!searchHeaderColumns.createdBy.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="createdBy"
            value={searchHeaderColumns.createdBy.searchText}
            disabled={searchHeaderColumns.createdBy.disableSearch}
            placeholder="Search created by"
          />
        </div>
      </th>
      <th className="-title _20">
        <div className="-table-header-title">Color</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="color"
            value={!searchHeaderColumns.color.disableSearch}
            checked={!searchHeaderColumns.color.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="color"
            value={searchHeaderColumns.color.searchText}
            disabled={searchHeaderColumns.color.disableSearch}
            placeholder="Search color"
          />
        </div>
      </th>
    </tr>
  )
}

PracticeTagList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleFilter: PropTypes.func,
  handleQuery: PropTypes.func,
  handleSetPagination: PropTypes.func.isRequired,
  paginatedList: PropTypes.array.isRequired,
  sortedAndFilteredList: PropTypes.array,
  tagList: PropTypes.object,
  viewingAs: PropTypes.string,
}

PracticeTagList.defaultProps = {
  allTags: null,
  handleFilter: null,
  handleQuery: null,
  handleSort: null,
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  const { tagList, tagListItems } = props
  const tagStore = store.tag
  const userMap = store.user.byId
  let paginatedList = []
  let sortedAndFilteredList = []

  if (tagListItems) {
    const tagMap = store.tag.byId

    const query = tagList.query

    // FILTER BY QUERY
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    tagListItems.forEach(item => {
      if (!item) {
        return
      }

      if (userMap[item._createdBy]) {
        const user = userMap[item._createdBy]
        item.createdBy = user.firstname || ''
        item.createdBy += (user.firstname ? ' ' : '') + user.lastname || ''
      }

      let showMe = true
      if (queryTestString && queryTestString.trim()) {
        showMe = item && filterUtils.filterTag(queryTestString, item)
      }

      if (
        showMe &&
        !!tagStore.filterHeaders &&
        !!tagStore.filterHeaders.name &&
        tagStore.filterHeaders.name.trim()
      ) {
        const name = tagStore.filterHeaders.name.toLowerCase()
        showMe = item.name && item.name.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!tagStore.filterHeaders &&
        !!tagStore.filterHeaders.type &&
        tagStore.filterHeaders.type.trim()
      ) {
        const type = tagStore.filterHeaders.type.toLowerCase()
        showMe = item.type && item.type.toLowerCase().indexOf(type) > -1
      }

      if (
        showMe &&
        !!tagStore.filterHeaders &&
        !!tagStore.filterHeaders.createdBy &&
        tagStore.filterHeaders.createdBy.trim()
      ) {
        const createdBy = tagStore.filterHeaders.createdBy.toLowerCase()
        showMe =
          item.createdBy && item.createdBy.toLowerCase().indexOf(createdBy) > -1
      }

      if (
        showMe &&
        !!tagStore.filterHeaders &&
        !!tagStore.filterHeaders.color &&
        tagStore.filterHeaders.color.trim()
      ) {
        const color = tagStore.filterHeaders.color.toLowerCase()
        showMe =
          item.color && item.color.toLowerCase().indexOf(color) > -1
      }

      if (showMe) sortedAndFilteredList.push(item)
    })

    const pagination = tagList.pagination || { page: 1, per: 50 }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(sortedAndFilteredList, start, end)
  }

  return {
    paginatedList: paginatedList,
    sortedAndFilteredList: sortedAndFilteredList,
    userMap,
    tagStore: store.tag,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeTagList))
