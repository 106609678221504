// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _cloneDeep from 'lodash/cloneDeep'

import withRouter from 'react-router-dom/withRouter'

import Binder from '../../../../global/components/Binder.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import routeUtils from '../../../../global/utils/routeUtils'

// import compoents
import SingleClientOptions from './SingleClientOptions.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js.jsx'
import EllipsisText from '../../../../global/components/EllipsisText'

// import actions
import * as clientActions from '../../clientActions'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

class PracticeClientSettingsListItem extends Binder {
  constructor(props) {
    super(props)
    this.feedbackMessage = React.createRef()
    this.state = {
      singleClientDropDownOption: false,
      showAlertModal: false,
    }

    this._bind(
      '_handleCloseSingleClientOptions',
      '_setStatus',
      '_toggleAlertModal',
      '_handleFavoriteToggle'
    )
  }

  _handleCloseSingleClientOptions(e) {
    e.stopPropagation()
    this.setState({ singleClientDropDownOption: false })
  }

  _setStatus(status) {
    const { dispatch, match, client, clientStore, listArgs, handleFetchList } =
      this.props
    const clientMap = clientStore && clientStore.byId
    const newClient = clientMap && clientMap[client._id]

    if (status === 'visible') {
      // can reinstate archived client list if have a same client name in visible status
      let clients = clientMap ? Object.keys(clientMap) : []
      clients = clients.filter(clientId => {
        if (clientMap[clientId]) {
          if (clientMap[clientId].status === 'visible') {
            let client = clientMap[clientId].name
              ? clientMap[clientId].name.trim().toLowerCase()
              : null
            let compareClient = newClient.name
              ? newClient.name.trim().toLowerCase()
              : null
            if (client && compareClient && client === compareClient) {
              return clientId
            }
          }
        }
      })

      if (clients && clients.length) {
        this.setState({ showAlertModal: true })
      } else {
        newClient.status = status
        dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
          if (json.success && json.id) {
            dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
            dispatch(
              clientActions.returnClientListPromise(
                ...routeUtils.listArgsFromObject({
                  _firm: match.params.firmId,
                  status: status,
                })
              )
            ).then(result => {
              if (result.success && result.list) {
                dispatch(
                  clientActions.addClientToList(
                    json.item,
                    ...routeUtils.listArgsFromObject({
                      _firm: match.params.firmId,
                      status: status,
                    })
                  )
                )
              }
            })
          }
        })
      }
    } else if (status === 'forced') {
      this.setState({ showAlertModal: false })
      // newClient.name += " (2)";
      // newClient.status = "visible";
      // dispatch(clientActions.sendUpdateClientStatus(newClient));
    } else {
      newClient.status = status
      dispatch(clientActions.sendUpdateClientStatus(newClient)).then(json => {
        if (json.success && json.id) {
          dispatch(clientActions.removeClientFromList(json.id, ...listArgs))
          dispatch(
            clientActions.returnClientListPromise(
              ...routeUtils.listArgsFromObject({
                _firm: match.params.firmId,
                status: status,
              })
            )
          ).then(result => {
            if (result.success && result.list) {
              dispatch(
                clientActions.addClientToList(
                  json.item,
                  ...routeUtils.listArgsFromObject({
                    _firm: match.params.firmId,
                    status: status,
                  })
                )
              )
            }
          })
        }
      })
    }
  }

  _toggleAlertModal() {
    this.setState({ showAlertModal: false })
  }

  _handleFavoriteToggle(isFavorite, clientId) {
    const { dispatch, clientStore } = this.props
    const data = {
      ...clientStore.byId[clientId],
      _id: clientId,
      isFavorite,
    }

    dispatch(clientActions.updateFavoriteClient(data)).then(json => {
      if (!json.success) {
        console.error('Could not toggle workspace favorite!')
        this.feedbackMessage.current.showError(
          'Could not change workspace favorite status!',
        )
      } else {
        this.feedbackMessage.current.showSuccess(
          isFavorite
            ? 'Workspace marked as favorite!'
            : 'Workspace removed from favorites!',
        )
      }
    })
  }

  render() {
    const {
      client,
      handleSelectedClient,
      checked,
      selectedDisplayColumns,
      ISARCHIVEDVIEW,
      ownerPermissions,
    } = this.props

    const { singleClientDropDownOption, showAlertModal } = this.state

    return (
      <div className="table-row -file-item -option-pointer">
        {(ownerPermissions && (
          <div className="table-cell">
            <CheckboxInput
              name="client"
              value={checked}
              checked={checked}
              change={() => handleSelectedClient(client._id)}
            />
          </div>
        )) ||
          null}
        {(ownerPermissions && (
          <div className="table-cell">
            <div
              className="-options"
              onClick={() =>
                this.setState({ singleClientDropDownOption: true })
              }
            >
              <div
                style={{ position: 'relative', height: '100%', width: '100%' }}
              >
                <CloseWrapper
                  isOpen={singleClientDropDownOption}
                  closeAction={this._handleCloseSingleClientOptions}
                />
                <i className="far fa-ellipsis-v"></i>
                <SingleClientOptions
                  isOpen={singleClientDropDownOption}
                  setStatus={this._setStatus}
                  singleClient={true}
                  archived={ISARCHIVEDVIEW}
                  handleFavoriteToggle={() =>
                    this._handleFavoriteToggle(!client.isFavorite, client._id)
                  }
                  client={client}
                />
              </div>
            </div>
          </div>
        )) ||
          null}
        {selectedDisplayColumns && selectedDisplayColumns.length
          ? selectedDisplayColumns.map(column => (
              <div
                key={column.key + client._id}
                className="table-cell"
                style={column.style}
              >
                <div
                  className="overflow-hidden"
                >
                  {client[column.key] ? (
                    !!column.valueFunction && !ISARCHIVEDVIEW ? (
                      <EllipsisText text={client[column.key]}>
                        {column.valueFunction(client, column.params)}
                      </EllipsisText>
                    ) : (
                      <EllipsisText>
                        {client[column.key]}
                      </EllipsisText>
                    )
                  ) : column.key === 'staffClientsCount' ? (
                    <em>0</em>
                  ) : (
                    <em>n/a</em>
                  )}
                </div>
              </div>
            ))
          : null}
        <AlertModal
          alertMessage={
            'This client cannot be reinstated because an active client with the same name already exists.'
          }
          alertTitle={'Warning: Duplicate name'}
          closeAction={this._toggleAlertModal}
          confirmAction={() => this._setStatus('forced')}
          confirmText={'Okay'}
          isOpen={this.state.showAlertModal}
          type={'danger'}
        ></AlertModal>
        <FeedbackMessage ref={this.feedbackMessage} />
      </div>
    )
  }
}

PracticeClientSettingsListItem.propTypes = {
  address: PropTypes.object,
  client: PropTypes.object.isRequired,
  phoneNumber: PropTypes.object,
  primaryContact: PropTypes.object,
}

PracticeClientSettingsListItem.defaultProps = {
  address: null,
  phoneNumber: null,
  primaryContact: null,
}

const mapStoreToProps = store => {
  return {
    clientStore: store.client,
    clientUserStore: store.clientUser,
    loggedInUser: store.user.loggedIn.user,
    userStore: store.user,
  }
}

export default withRouter(
  connect(mapStoreToProps)(PracticeClientSettingsListItem)
)
