export const getShareAuthTypes = (
  staffStore,
  loggedInUser,
  hasIndividual = false,
) => {
  const staff = Object.values(staffStore.byId).find(
    s => s._user === loggedInUser._id,
  )

  const authTypes = [
    { display: 'Direct Link', val: 'none' },
    { display: 'Question/Answer', val: 'secret-question' },
  ]

  const individualAuthType = {
    display: 'Individual Authentication',
    val: 'individual-auth',
  }

  const questionAuthType = {
    display: 'Question/Answer',
    val: 'secret-question',
  }

  if (loggedInUser.isAdmin || staff?.allowDirectLink) {
    return hasIndividual ? [...authTypes, individualAuthType] : authTypes
  }

  return hasIndividual
    ? [questionAuthType, individualAuthType]
    : [questionAuthType]
}
