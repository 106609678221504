/**
 * Sets up the routing for all Firm Client Workspace views.
 *
 * NOTE: As an example, we've included two other Route Components that protect a given
 * path: LoginRoute and RoleRoute
 *
 * LoginRoute simply checks if the user is logged in and if NOT, it redirects
 * them to the login page.
 *
 * RoleRoute protects the path to make sure the user is A) logged in and B) has
 * role matching the path=/Firm/clients.
 */

// import primary libraries
import React from 'react'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import YTRoute from '../../../global/components/routing/YTRoute.js.jsx'

// import client views
import PracticeClientSettingsList from './views/PracticeClientSettingsList.js.jsx'
import PracticeUpdateClientWorkflow from '../../clientWorkflow/practice/views/PracticeUpdateClientWorkflow.js.jsx'

import PracticeSingleFile from '../../file/practice/views/PracticeSingleFile.js.jsx'
import WorkspaceActivity from '../../activity/practice/views/WorkspaceActivity.js.jsx'
import WorkspaceClientDetails from './views/WorkspaceClientDetails.js.jsx'
import WorkspaceFiles from '../../file/practice/views/WorkspaceFiles.js.jsx'

import PracticeWorkspaceNotesAboutClient from '../../note/practice/views/PracticeWorkspaceNotesAboutClient.js.jsx'
import ClientPostPracticeList from '../../clientPost/practice/views/ClientPostPracticeList.js.jsx'
import SingleClientPost from '../../clientPost/views/SingleClientPost.js.jsx'
import PracticeClientWorkflowTemplates from '../../clientWorkflowTemplate/practice/views/PracticeClientWorkflowTemplates.js.jsx'
import WorkspaceUsers from './views/WorkspaceUsers.js.jsx'
import ArchivedFiles from '../../file/practice/views/ArchivedFiles.js.jsx'

import ArchivedQuickTaskList from '../../quickTask/practice/views/ArchivedQuickTaskList.js.jsx'
import PracticeQuickTaskList from '../../quickTask/practice/views/PracticeQuickTaskList.js.jsx'
import NotificationWorkspaceLayout from '../../notification/components/NotificationWorkspaceLayout.js.jsx'

import WorkspaceRequestFolders from '../../requestFolder/views/WorkspaceRequestFolders.js.jsx'
import WorkspaceRequests from '../../request/views/WorkspaceRequests.js.jsx'

import WorkspaceRequestTasks from '../../requestTask/views/WorkspaceRequestTasks.js.jsx'

import PracticeCreateClient from './views/PracticeCreateClient.js.jsx'
import PracticeClientBulkImport from './views/PracticeClientBulkImport.js.jsx'
import PracticeClientOverview from './views/PracticeClientOverview.js.jsx'
import ClientSettingsLinks from '../components/ClientSettingsLinks.js.jsx'
import ClientSettingsContacts from '../../user/practice/views/ClientSettingsContacts.js.jsx'
import ClientSettingsAssignedStaff from '../../staff/practice/views/ClientSettingsAssignedStaff.jsx'
import PracticeInviteClientUser from '../../clientUser/practice/views/PracticeInviteClientUser.js.jsx'

class ClientWorkspaceRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    const firmId = this.props.location.pathname.split('/')[2]
    const clientId = this.props.location.pathname.split('/')[4]
      ? this.props.location.pathname.split('/')[4]
      : null
    const breadcrumbs = this.props.breadcrumbs

    return (
      <Switch>
        <YTRoute
          breadcrumbs={[{ display: 'Workspaces', path: null }]}
          component={PracticeClientSettingsList}
          path="/firm/:firmId/workspaces"
          exact
          staff={true}
        />
        <YTRoute
          component={PracticeClientSettingsList}
          path="/firm/:firmId/workspaces?page=:currentPage&per=:perPage"
          exact
          staff={true}
        />
        <YTRoute
          breadcrumbs={[{ display: 'Workspaces', path: null }]}
          component={PracticeClientSettingsList}
          path="/firm/:firmId/workspaces/archived"
          exact
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'New ', path: null },
          ]}
          component={PracticeCreateClient}
          exact
          path="/firm/:firmId/workspaces/new"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Import ', path: null },
          ]}
          component={PracticeClientBulkImport}
          exact
          path="/firm/:firmId/workspaces/import"
          staffOwner={true}
        />
        {/* <YTRoute 
          breadcrumbs={[{display: 'Workspaces', path: `/firm/${firmId}/workspaces`}, {display: 'Files ', path: null}]}
          //component={PracticeClientOverview}
          component={WorkspaceFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files"
        /> */}
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Overview ', path: null },
          ]}
          component={PracticeClientOverview}
          exact
          path="/firm/:firmId/workspaces/:clientId/overview"
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Links', path: null },
          ]}
          component={ClientSettingsLinks}
          path="/firm/:firmId/workspaces/:clientId/links"
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Contacts', path: null },
          ]}
          component={ClientSettingsContacts}
          exact
          path="/firm/:firmId/workspaces/:clientId/contacts/:contactsStatus"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Contacts', path: null },
          ]}
          component={PracticeInviteClientUser}
          path="/firm/:firmId/workspaces/:clientId/contacts/invite"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Contacts', path: null },
          ]}
          component={ClientSettingsContacts}
          path="/firm/:firmId/workspaces/:clientId/contacts"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Assigned staff', path: null },
          ]}
          component={ClientSettingsAssignedStaff}
          path="/firm/:firmId/workspaces/:clientId/staff?page=:currentPage&per=:perPage"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Assigned staff', path: null },
          ]}
          component={ClientSettingsAssignedStaff}
          path="/firm/:firmId/workspaces/:clientId/staff"
          staffOwner={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Activity', path: null },
          ]}
          component={WorkspaceActivity}
          exact
          path="/firm/:firmId/workspaces/:clientId/activity"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            {
              display: 'Single Client',
              path: `/firm/${firmId}/workspaces/${clientId}`,
            },
            { display: 'Update Client Workflow', path: null },
          ]}
          component={PracticeUpdateClientWorkflow}
          exact
          path="/firm/:firmId/workspaces/:clientId/client-workflows/:clientWorkflowId/update"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Archived Quick Tasks', path: null },
          ]}
          component={ArchivedQuickTaskList}
          path="/firm/:firmId/workspaces/:clientId/quick-tasks/archived"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Quick Tasks', path: null },
          ]}
          component={PracticeQuickTaskList}
          path="/firm/:firmId/workspaces/:clientId/quick-tasks"
          staff={true}
        />
        <YTRoute
          component={PracticeClientWorkflowTemplates}
          path="/firm/:firmId/workspaces/:clientId/client-workflow-templates"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Notes', path: null },
          ]}
          component={PracticeWorkspaceNotesAboutClient}
          exact
          path="/firm/:firmId/workspaces/:clientId/notes"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Messages', path: null },
          ]}
          component={ClientPostPracticeList}
          exact
          path="/firm/:firmId/workspaces/:clientId/messages"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Archived Messages', path: null },
          ]}
          component={ClientPostPracticeList}
          exact
          path="/firm/:firmId/workspaces/:clientId/messages/archived"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Messages', path: null },
          ]}
          component={SingleClientPost}
          exact
          path="/firm/:firmId/workspaces/:clientId/messages/:clientPostId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Messages', path: null },
          ]}
          component={SingleClientPost}
          exact
          path="/firm/:firmId/workspaces/:clientId/messages/archived/:clientPostId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Details', path: null },
          ]}
          component={WorkspaceClientDetails}
          exact
          path="/firm/:firmId/workspaces/:clientId/details"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Users', path: null },
          ]}
          component={WorkspaceUsers}
          path="/firm/:firmId/workspaces/:clientId/users"
          staff={true}
        />
        <YTRoute
          breadcrumbs={[
            { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
            { display: 'Notifications', path: null },
          ]}
          component={NotificationWorkspaceLayout}
          path="/firm/:firmId/workspaces/:clientId/notifications"
          staff={true}
        />
        {/*  *********************************************************************************************************************************************/}
        {/*  *************************************************  WORKSPACE REQUEST CONNECTED ROUTE START  ****************************************************/}
        {/*  *********************************************************************************************************************************************/}
        {/* <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequestTasks}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-list/:requestId/unpublished"
          staff={true}
        /> */}
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequestTasks}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-list/:requestId/:requestTaskStatus/task-activity/:requestTaskId/:viewingAs"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequestTasks}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-list/:requestId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequestTasks}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-list/:requestId/:requestTaskStatus"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequests}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-list"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceRequestFolders}
          exact
          path="/firm/:firmId/workspaces/:clientId/request-folder"
          staff={true}
        />

        {/*  *********************************************************************************************************************************************/}
        {/*  *************************************************  WORKSPACE FILE CONNECTED ROUTE START  ****************************************************/}
        {/*  *********************************************************************************************************************************************/}
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:folderId/folder/file-activity/:fileActivityId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={PracticeSingleFile}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/archived/:folderId/folder/:fileId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={ArchivedFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/archived/:folderId/folder"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={PracticeSingleFile}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:folderId/folder/archived/:fileId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={ArchivedFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:folderId/folder/archived"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={PracticeSingleFile}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:folderId/folder/:fileId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:folderId/folder"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/file-activity/:fileActivityId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={ArchivedFiles}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/archived"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={PracticeSingleFile}
          exact
          staff={true}
          path="/firm/:firmId/workspaces/:clientId/files/archived/:fileId"
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={PracticeSingleFile}
          exact
          path="/firm/:firmId/workspaces/:clientId/files/:fileId"
          staff={true}
        />
        <YTRoute
          breadcrumbs={breadcrumbs}
          component={WorkspaceFiles}
          path="/firm/:firmId/workspaces/:clientId/files"
          staff={true}
        />
        {/*  *********************************************************************************************************************************************/}
        {/*  *************************************************  WORKSPACE FILE CONNECTED ROUTE START  ****************************************************/}
        {/*  *********************************************************************************************************************************************/}
      </Switch>
    )
  }
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  // url path
  const { match } = props
  const path = props.location.pathname.split('/')

  // request folder
  const requestFolder =
    store.requestFolder && store.requestFolder.byId
      ? store.requestFolder.byId
      : null
  const _requestFolder = path[5] === 'request-folder'

  // request list
  const request =
    store.request && store.request.byId ? store.request.byId : null
  const _request = path[5] === 'request-list'

  const firmId = match.params.firmId
  const _workspace =
    path[3] === 'workspaces' &&
    (path[5] === 'files' || path[5] === 'request-list')
  const clientId = _workspace ? path[4] : match.params.clientId

  let breadcrumbs = [
    { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
    { display: 'Files', path: `/firm/${firmId}/workspaces/${clientId}/files` },
  ]

  if (_requestFolder && requestFolder) {
    const requestFolderId = match.params.requestFolderId
      ? match.params.requestFolderId
      : path[6]

    // view with selected client
    if (clientId) {
      breadcrumbs = [
        { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
        // , {display: 'Shared Folders', path: `/firm/${firmId}/workspaces/${clientId}/request-folder`}
      ]
    }

    if (requestFolder[requestFolderId]) {
      breadcrumbs.splice(2, 0, {
        display: requestFolder[requestFolderId].name,
        path: `/firm/${firmId}/workspaces/${clientId}/request-folder/${requestFolderId}/request-list`,
      })
    }
  }

  if (_request && request) {
    const requestId = match.params.requestId ? match.params.requestId : path[6]

    if (clientId) {
      breadcrumbs = [
        { display: 'Workspaces', path: `/firm/${firmId}/workspaces` },
        {
          display: 'Request Lists',
          path: `/firm/${firmId}/workspaces/${clientId}/request-list`,
        },
      ]
    }

    if (request[requestId]) {
      breadcrumbs.splice(3, 0, {
        display: request[requestId].name,
        path: `/firm/${firmId}/workspaces/${clientId}/request-list/${requestId}/unpublished`,
      })
    }
  }

  return {
    fileStore: store.file,
    breadcrumbs,
  }
}

export default withRouter(connect(mapStoreToProps)(ClientWorkspaceRouter))
